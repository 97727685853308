import React, { Component, Suspense } from 'react'
import { AuthProvider } from 'react-auth-kit'
import { RequireAuth } from 'react-auth-kit'
import { BrowserRouter, HashRouter, Router, Route, Routes } from 'react-router-dom'
import './App.css'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Privacy = React.lazy(() => import('./views/terms/Privacy'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

//Feedback
const Feedback = React.lazy(() => import('./views/feedback/Feedback'));

//Sign Pdf
// const SignDocuments = React.lazy(() => import('./views/advanceddocuments/SignDocuments'));
const SignDocuments = React.lazy(() => import('./views/advanceddocuments/SignDocumentsv2'));
// const SenderSignDocuments = React.lazy(() => import('./views/advanceddocuments/SenderSignDocuments'));
const SenderSignDocuments = React.lazy(() => import('./views/advanceddocuments/SenderSignDocumentsv2'));
// const SenderDocPrevBeforeSend = React.lazy(() => import('./views/advanceddocuments/SenderDocPrevBeforeSend'));
const SenderDocPrevBeforeSend = React.lazy(() => import('./views/advanceddocuments/SenderDocPrevBeforeSendv2'));
// const SignGmailAttachments = React.lazy(() => import('./views/advanceddocuments/SignGmailAttachments'));
const SignGmailAttachments = React.lazy(() => import('./views/advanceddocuments/SignGmailAttachmentsv2'));

const DocSignAlready = React.lazy(() => import('./views/advanceddocuments/DocSignedAlready'));
const DocSignedThankYou = React.lazy(() => import('./views/advanceddocuments/DocSignedThankYou'));
const ProcessingPayment = React.lazy(() => import('./views/billing/ProcessingPayment'));
// const CustJs = React.lazy(() => import('./components/AppMailViewTrace.js'))

class App extends Component {
  render() {
    return (
      <>
      <BrowserRouter>
      {/*<HashRouter>*/}
        {/*<Suspense fallback={loading}>*/}
        <Suspense>
          <AuthProvider authType = {'cookie'}
                    authName={'_auth'}
                    cookieDomain={window.location.hostname}
                    cookieSecure={window.location.protocol === "https:"}>
            <Routes>
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route exact path="/extension-uninstalled" name="Feedback Page" element={<Feedback />} />
              <Route exact path="/documents-preview" name="Sign PDF Page" element={<SignDocuments />} />
              <Route exact path="/documents-sender-preview" name="Sign PDF Page" element={<SenderSignDocuments />} />
              <Route exact path="/document-signed" name="Document is signed" element={<DocSignAlready />} />
              <Route exact path="/document-signed-confirmation" name="Thank you for singing the document." element={<DocSignedThankYou />} />
              <Route exact path="/pre-document-preview" name="Document Preview" element={<SenderDocPrevBeforeSend />} />
              <Route exact path="/document-self-signature" name="Self Signature Document Preview" element={<SignGmailAttachments />} />
              <Route exact path="/processing-payment" name="Stripe Payment Processing" element={<ProcessingPayment />} />
              <Route exact path="/privacy-policy" name="Privacy" element={<Privacy />} />
              {/*<Route exact path="/register" name="Register Page" element={<Register />} />*/}
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              {/*<Route path="*" name="Home" element={<RequireAuth loginPath={"/login/?r="+window.location.href}><DefaultLayout /></RequireAuth>} />*/}
              <Route path="*" name="Home" element={<RequireAuth loginPath="/login"><DefaultLayout /></RequireAuth>} />
            </Routes>
          </AuthProvider>
        </Suspense>
      {/*</HashRouter>*/}
      </BrowserRouter>
      </>
    )
  }
}

export default App
